import { create } from 'zustand';
import { pb } from '../services/pocketbase';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../components/navigation/routes';


const useAuthStore = create(
    persist(
        (set, get) => ({
            user: null,
            isLoading: true,
            authData: null,

            login: async (provider) => {
                try {
                    // Optionally open a window to show login process
                    var w = window.open("", "authWindow", "width=500,height=600");

                    pb.authStore.clear();

                    const authData = await pb.collection('users').authWithOAuth2({
                        provider,
                        urlCallback: (url) => {
                            if (w) {
                                // Redirect the opened window to the provider's login page
                                w.location.href = url;
                            } else {
                                // Fallback if the window failed to open
                                window.location.href = url;
                            }
                        },
                    });

                    const meta = authData.meta;
                    let updatedUser = authData.record;

                    if (meta.isNew) {
                        const formData = new FormData();

                        const response = await fetch(meta.avatarUrl);

                        if (response.ok) {
                            const file = await response.blob();
                            formData.append('avatar', file);
                        }

                        formData.append('name', meta.name);

                        updatedUser = await pb.collection('users').update(authData.record.id, formData);
                    }

                    set({ authData: authData });
                    set({ user: updatedUser, isLoading: false });

                    // Close the popup window if it's still open
                    if (w) {
                        w.close();
                    }

                    return true;

                } catch (error) {
                    set({ user: null, isLoading: false });
                    return false;
                }
            },

            logout: () => {
                pb.authStore.clear();
                set({ user: null, isLoading: false, authData: null });
            },
            updateBalance: async () => {
                const { user } = get();
                if (user) {
                    try {
                        // Fetch current user data
                        const updatedUser = await pb.collection('users').getOne(user.id);
                        const currentBalance = updatedUser.balance; // Assuming balance is a field in the user table

                        // Update Zustand state with new balance
                        set((state) => ({
                            user: { ...state.user, balance: currentBalance },
                        }));
                    } catch (error) {
                        console.error("Error fetching user balance:", error);
                    }
                }
            }
        }),
        {
            name: 'auth-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

// Create a custom hook to handle login and logout with navigation
export const useAuth = () => {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const loginWithRedirect = async (provider) => {
        const success = await authStore.login(provider);
        console.log({ success });
        if (success) {
            // Redirect to home page after successful login
            navigate(ROUTES.DASHBOARD, { replace: true });
        }
    };

    const logoutWithRedirect = () => {
        authStore.logout();
        navigate(ROUTES.SIGNIN, { replace: true });
    };

    return { ...authStore, logout: logoutWithRedirect, login: loginWithRedirect };
};

export default useAuthStore;