import RouterComponent from './app/components/navigation/RouterComponent';
import { useEnglishLessonStore } from './app/store/englishLessonStore';

function App() {

  const { theme } = useEnglishLessonStore()
  return (
    <div data-bs-theme={theme}><RouterComponent /></div>
  );
}

export default App;
