import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Gear } from 'react-bootstrap-icons'
import { pb } from '../../services/pocketbase'
import { useEnglishLessonStore } from '../../store/englishLessonStore'
import { makeRequestAi } from '../../services/makeRequestAi'
import LoadingComponent from '../elements/LoadingComponent'
import GenerateButtonComponent from '../elements/buttons/GenerateButtonComponent'
import toast from 'react-hot-toast'
import DeleteButtonComponent from '../elements/buttons/DeleteButtonComponent'
import { useExersisesStore } from '../../store/exercisesStore'
import EditButtonComponent from '../elements/buttons/EditButtonComponent'
import SaveButtonComponent from '../elements/buttons/SaveButtonComponent'

function AutoYoutubeGenerationComponent() {
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState(false)
  const { inputText, setInputText, url, setUrl, clearWordList, clearWordsDictionary, setTranscript, tokensCount, setEditing, editing } = useEnglishLessonStore()
  const { clearAllExercise } = useExersisesStore()

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsOpen(!isOpen)
    }
  }

  const saveResult = (newState) => {
    setTranscript(newState)
    setInputText(newState)
  }

  const mkAiRequest = async (text) => {
    await makeRequestAi('CorrectTranscription', (newState) => setStatus(newState), { text: text }, (newState) => saveResult(newState));
  }

  const handleSubmit = async () => {
    setStatus(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/youtube', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${pb.authStore.token}`
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        throw new Error(await response.json());
      }

      const data = await response.json();
      setInputText(data.text);
      setTranscript("");
      // get env TOKEN_LIMIT
      if (data.text && tokensCount < Number(process.env.REACT_APP_TOKEN_LIMIT) / 2) {
        await mkAiRequest(data.text)
      } if (tokensCount >= Number(process.env.REACT_APP_TOKEN_LIMIT) / 2) {
        toast.error('Слишком длинный текст. Необходимо уменьшить количество символов.');
        setStatus(false);
      }
    } catch (err) {
      console.error(err.message)
      setStatus(false);
    }
  };


  /**
   * If user confirms, delete all text in the input field
   */
  const handleDeleteAll = () => {
    if (window.confirm('Удалить все?')) {
      setInputText("")
      clearWordList();
      clearWordsDictionary();
      clearAllExercise();
      setUrl("");
      setTranscript("");
      toast.success('Всё удалено');
    }
  }

  const editHandler = () => {
    setEditing(!editing)
  }


  return (
    <div className='mb-3'>
      <div className='d-flex align-items-center justify-content-start'>
        {isOpen &&
          <>
            <Form.Control
              onChange={(e) => setUrl(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              type="text"
              placeholder="введите url Youtube ролика"
              value={url}
              className='shadow-box mb-2'
            />
          </>
        }
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='my-1'>
          <Button onClick={() => setIsOpen(!isOpen)} variant={isOpen ? 'outline-danger' : 'primary'}><Gear /> {!isOpen ? 'Скачать из Youtube' : 'Закрыть' }</Button>
          {isOpen &&
            <>
              <GenerateButtonComponent className={'mx-2'} onClick={handleSubmit} disabled={url?.length < 1}>Скачать</GenerateButtonComponent>
              <GenerateButtonComponent variant={'outline-primary'} onClick={() => mkAiRequest(inputText)} disabled={inputText?.length < 1}>Улучшить</GenerateButtonComponent>
              {!!status &&
                <LoadingComponent />
              }
            </>}
        </div>
        <div>
          {editing ? <SaveButtonComponent title={'Сохранить'} onClick={() => editHandler()} style={{ marginRight: '20px' }} /> : <EditButtonComponent title={'Редактировать'} onClick={() => editHandler()} style={{ marginRight: '20px' }} />}
          <DeleteButtonComponent onClick={() => handleDeleteAll()} title="Удалить все" />
        </div>

      </div>
    </div>
  )
}

export default AutoYoutubeGenerationComponent