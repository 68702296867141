import llamaTokenizer from 'llama-tokenizer-js';
import toast from 'react-hot-toast';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const useEnglishLessonStore = create(
    persist(
        (set, get) => ({

            // main text 
            inputText: "",
            tokensCount: 0,
            setInputText: (text) => set(() => ({ inputText: text, tokensCount: llamaTokenizer.encode(text).length })),
            // editing
            editing: false,
            setEditing: (text) => set(() => ({ editing: text })),

            url: "",
            setUrl: (text) => set(() => ({ url: text })),

            transcript: "",
            setTranscript: (text) => set(() => ({ transcript: text })),

            // theme
            theme: "light",
            setTheme: (text) => set(() => ({ theme: text })),

            // select to wordlist
            selectedText: "",
            setSelectedText: (text) => set(() => ({ selectedText: text })),


            // main wordlist

            wordList: [],
            addToWordList: (word) => {
                if (!get().wordList.includes(word) && word.length > 0) {
                    toast('+ ' + word,
                        {
                            icon: '📑',
                            duration: 2000,
                        }
                    );
                    set((state) => ({
                        wordList: [...state.wordList, word]
                    }));
                } else {
                    if (word.trim().length > 0) {
                        toast('Слово уже в списке',
                            {
                                icon: '👏',
                                duration: 2000,
                            }
                        );
                    } else {
                        toast('Не добавлено',
                            {
                                icon: '🤬',
                                duration: 2000,
                            }
                        );
                    }

                }
            },
            deleteFromWordList: (word) => {
                const currentList = get().wordList;
                const updatedList = currentList.filter(item => item !== word);
                set(() => ({
                    wordList: updatedList
                }));
            },
            editWordInList: (wordToEdit, newWord) => {
                const currentList = get().wordList;
                const index = currentList.findIndex(item => item === wordToEdit);
                if (index !== -1) {
                    const updatedList = [...currentList];
                    updatedList[index] = newWord;
                    set(() => ({
                        wordList: updatedList
                    }));
                }
            },
            clearWordList: () => set(() => ({ wordList: [] })),

            // meanings
            // structure of dictionary: {"current_word": { definitions: ["",""], usages:["","",""], translations:["",""], phonetics:["",""] }}
            wordsDictionary: {},

            /**
             * Adds a word to the dictionary, merging existing definitions, usages, and translations.
             *
             * @param {string} word - The word to add to the dictionary.
             * @param {array} definitions - An array of definitions for the word.
             * @param {array} usages - An array of example usages for the word.
             * @param {array} translations - An array of translations for the word.
             * @param {array} phonetics - An array of translations for the word.
             * @return {void}
             */

            addWordToDictionary: (word, definitions = [], usages = [], translations = [], phonetics = []) => {
                set((state) => ({
                    wordsDictionary: {
                        ...state.wordsDictionary,
                        [word]: {
                            definitions: state.wordsDictionary[word]?.definitions
                                ? [...new Set([...state.wordsDictionary[word].definitions, ...definitions])]
                                : [...new Set(definitions)],
                            usages: state.wordsDictionary[word]?.usages
                                ? [...new Set([...state.wordsDictionary[word].usages, ...usages])]
                                : [...new Set(usages)],
                            translations: state.wordsDictionary[word]?.translations
                                ? [...new Set([...state.wordsDictionary[word].translations, ...translations])]
                                : [...new Set(translations)],
                            phonetics: state.wordsDictionary[word]?.phonetics
                                ? [...new Set([...state.wordsDictionary[word].phonetics, ...phonetics])]
                                : [...new Set(phonetics)]
                        }
                    }
                }));
            },

            deleteWordFromDictionary: (word) => {
                const currentDictionary = get().wordsDictionary;
                const updatedDictionary = { ...currentDictionary };
                if (updatedDictionary[word]) {
                    delete updatedDictionary[word];
                }
                set(() => ({
                    wordsDictionary: updatedDictionary
                }));
            },

            deleteAttributeFromDictionary: (word, attribute, text) => {
                const currentDictionary = get().wordsDictionary;
                const updatedDictionary = { ...currentDictionary };
                if (updatedDictionary[word] && updatedDictionary[word][attribute]) {
                    updatedDictionary[word][attribute] = updatedDictionary[word][attribute].filter(item => item !== text);
                }
                set(() => ({
                    wordsDictionary: updatedDictionary
                }));
            },

            clearWordsDictionary: () => set(() => ({ wordsDictionary: {} })),
        }),
        {
            name: 'english-lesson-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);