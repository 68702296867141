import toast from "react-hot-toast";
import { ai } from "../ai";
import LangflowClient from "./langflowClient";
import { pb } from "./pocketbase";
import useAuthStore from "../store/AuthStore";

/**
 * Replaces placeholders in a given text with variables.
 *
 * @param {string} text - The text containing placeholders.
 * @param {object} vars - An object containing variables to replace placeholders.
 * @return {string} The text with placeholders replaced.
 */
const replacePlaceholders = (text, vars) => {
    return text.replace(/\{(.*?)\}/g, (match, p1) => {
        return vars[p1] || ""; // If the variable is not found, keep the placeholder
    });
};


/**m,
 * Makes a request to the LangflowClient with the given parameters.
 *
 * @param {string} aiName - The name of the AI to use for the request.
 * @param {function} setStatusInner - A function to set the status of the request.
 * @param {object} varsInner - An object containing variables to replace placeholders.
 * @param {function} setResultInner - A function to set the result of the request.
 * @return {Promise<void>} A Promise that resolves when the request is completed.
 */


export const makeRequestAi =
    process.env.REACT_APP_AI_BACKEND === "langflow"
        ? async (aiName, setStatusInner, varsInner, setResultInner) => {
            const langflowClient = new LangflowClient();
            console.log('Running langflow');
            setStatusInner(true);
            try {
                const flowIdOrName = ai[aiName].flowIdOrName;
                const inputValue = replacePlaceholders(ai[aiName].inputValue, varsInner);
                console.log("inputValue", inputValue)
                const response = await langflowClient.runFlow(
                    flowIdOrName,
                    inputValue,
                    {},
                    false,
                    (data) => console.log("Received:", data.chunk), // onUpdate
                    (message) => console.log("Stream Closed:", message), // onClose
                    (error) => console.log("Stream Error:", error) // onError
                );
                const flowOutputs = response.outputs[0];
                const firstComponentOutputs = flowOutputs.outputs[0];
                const output = firstComponentOutputs.outputs.message;
                setStatusInner(false);
                setResultInner(output.message.text)
                console.log("Final Output:", output.message.text);
                const updateBalance = useAuthStore.getState().updateBalance; // Access updateBalance directly
                await updateBalance();

            } catch (error) {
                console.error('Error running flow:', error);
                toast.error("Нет поключения к langflow")
                setStatusInner(0);
            }
        }
        : process.env.REACT_APP_AI_BACKEND === "go"
            ? async (aiName, setStatusInner, varsInner, setResultInner) => {
                setStatusInner(true);
                const baseURL = process.env.REACT_APP_API_URL + '/goai';
                const apiKey = pb.authStore.token

                async function post(endpoint, body, headers = { "Content-Type": "application/json" }) {
                    if (apiKey) {
                        headers["Authorization"] = `Bearer ${apiKey}`;
                    }
                    const url = `${baseURL}${endpoint}`;
                    // console.log('POST:', url, body);
                    try {
                        const response = await fetch(url, {
                            method: 'POST',
                            headers: headers,
                            body: JSON.stringify(body),
                        });
                        // console.log('response:',response);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return await response.json();
                    } catch (error) {
                        console.error('Request Error:', error);
                        throw error;
                    }
                }

                try {
                    console.log('Running REACT_AI_BACKEND go');
                    const flowIdOrName = ai[aiName].flowIdOrName;
                    const inputValue = replacePlaceholders(ai[aiName].inputValue, varsInner);
                    console.log("inputValue", inputValue)
                    console.log("flowIdOrName", flowIdOrName)
                    const tweaks = {}

                    const response = await post(`/goai/${flowIdOrName}`, { input_value: inputValue, tweaks: tweaks })
                    const output = response
                    setStatusInner(false);
                    setResultInner(output.choices[0].message.content)
                    console.log("Final Output:", output.choices[0].message.content);
                    const updateBalance = useAuthStore.getState().updateBalance; // Access updateBalance directly
                    await updateBalance();
                    // throw new Error("Implement me");
                    // const flowOutputs = response.outputs[0];
                    // const firstComponentOutputs = flowOutputs.outputs[0];
                    // const output = firstComponentOutputs.outputs.message;

                    // setStatusInner(false);
                    // setResultInner(output.message.text)
                    // console.log("Final Output:", output.message.text);


                    // const flowIdOrName = ai[aiName].flowIdOrName;
                    // const inputValue = replacePlaceholders(ai[aiName].inputValue, varsInner);
                    // console.log("inputValue", inputValue)
                    // const response = await langflowClient.runFlow(
                    //     flowIdOrName,
                    //     inputValue,
                    //     {},
                    //     false,
                    //     (data) => console.log("Received:", data.chunk), // onUpdate
                    //     (message) => console.log("Stream Closed:", message), // onClose
                    //     (error) => console.log("Stream Error:", error) // onError
                    // );
                    // const flowOutputs = response.outputs[0];
                    // const firstComponentOutputs = flowOutputs.outputs[0];
                    // const output = firstComponentOutputs.outputs.message;
                    // setStatusInner(false);
                    // setResultInner(output.message.text)
                    // console.log("Final Output:", output.message.text);

                } catch (error) {
                    console.error('Error running flow:', error);
                    toast.error("Нет поключения к AI Backend Go")
                    setStatusInner(0);
                }
            } : async (aiName, setStatusInner, varsInner, setResultInner) => {
                setStatusInner(true);
                try {
                    console.log('Set REACT_APP_AI_BACKEND');
                    throw new Error("Установите REACT_AI_BACKEND в .env");

                } catch (error) {
                    console.error('Error running flow:', error);
                    toast.error("Установите REACT_APP_AI_BACKEND в .env Сейчас".process.env.REACT_APP_AI_BACKEND)
                    setStatusInner(0);
                }
            }