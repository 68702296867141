import toast from "react-hot-toast";

export const copyToClipboard = async (htmltext, simpletext = htmltext) => {
    try {
        // Use the Clipboard API to copy HTML to the clipboard
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmltext], { type: 'text/html' }),
            'text/plain': new Blob([simpletext], { type: 'text/plain' }) // Fallback to plain text
          })
        ]);
        toast.success('Скопировано');
      } catch (err) {
        console.error('Failed to copy: ', err);
        toast.error('Ошибка при копировании');
      }
}