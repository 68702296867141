import React, { useRef } from 'react'
import DeleteButtonComponent from '../buttons/DeleteButtonComponent'
import { Col, Row } from 'react-bootstrap'
import { useExersisesStore } from '../../../store/exercisesStore'
import CopyToClipboardButtonComponent from '../buttons/CopyToClipboardButtonComponent'
import FindRightDefinitionChooseWordsComponent from './FindRightDefinitionChooseWordsComponent'
import { copyToClipboard } from '../../../functions/copyToClipboard';

function FindRightDefinitionExerciseBlockComponent({ exercise, exerciseType }) {
  const { deleteTypeItemExercise } = useExersisesStore()
  const tableRef = useRef(null); // Create a ref for the table element



  const deleteHandler = () => {
    if (window.confirm('Удалить задание?')) {
      deleteTypeItemExercise(exerciseType, exercise.id)
    }
  }

  const wordsString = exercise?.pairs && exercise.pairs.map((item) => (
    item.word
  )).join(', ')
  console.log({ sent: exercise })
  const pairsArray = exercise?.pairs && exercise.pairs


  const onClickCopyHandler = async () => {
    if (tableRef.current) {
      const html = tableRef.current.outerHTML; // Get the HTML of the table

      await copyToClipboard(html.replace(/<\/?tbody>/g, ''), tableRef.current.innerText)
    }
  }


  return (
    <>
      <div className='block-with-definition bg-blue shadow-box'>
        <div className='d-flex justify-content-between'>
          <div>{wordsString}</div>
          <span className='px-2'><DeleteButtonComponent onClick={deleteHandler} /></span>
        </div>
        <div>
          <h4>Упражнение <CopyToClipboardButtonComponent onClickHandler={() => onClickCopyHandler()} /></h4>
          <table ref={tableRef} className='table table-bordered'>
            <tbody>
              {pairsArray && pairsArray.map((items) => (
                <tr key={items.word}>
                  <td >{items.word}</td>
                  <td >{items.definition}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>

      </div>
      <div className='block-with-definition shadow-box'>
        <Row>
          <Col>
            <FindRightDefinitionChooseWordsComponent exercise={exercise} exerciseType={exerciseType} />
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <GenerateSentencesFillTheGapComponent exerciseType={exerciseType} exercise={exercise} /> */}
          </Col>
        </Row>

      </div>

    </>

  )
}

export default FindRightDefinitionExerciseBlockComponent