import React from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { useEnglishLessonStore } from '../store/englishLessonStore'
import AutoYoutubeGenerationComponent from './ai/AutoYoutubeGenerationComponent'
import TextAddToWordList from './TextAddToWordList'

function TextAreaComponent() {
    const { inputText, setInputText, tokensCount, editing } = useEnglishLessonStore()


    //maxRows={400} 
    return (
        <>
            <AutoYoutubeGenerationComponent />
            {editing ?
                <div className='p-5'>
                    <ReactTextareaAutosize value={inputText} onChange={(e) => setInputText(e.target.value)} className='bg-solid textarea' style={{ borderRadius: '15px', width: '100%',  overflow: 'hidden'}} />
                </div>
                :
                <TextAddToWordList inputText={inputText} />
            }
            <div onClick={() => { }} className={[' small', (Number(process.env.REACT_APP_TOKEN_LIMIT) || 0) / 2 > tokensCount ? 'green' : 'red'].join(" ")}>Количество токенов: {tokensCount}</div>
        </>
    )
}

export default TextAreaComponent