import React from 'react'
import { useEnglishLessonStore } from '../store/englishLessonStore'
import { highlightWords } from '../functions/highlightWords';

function TextAddToWordList({ inputText }) {
    const { selectedText, setSelectedText, addToWordList, wordList } = useEnglishLessonStore()


    const handleSelectionChange = (event) => {
        if (window.getSelection) {
            const selection = window.getSelection();
            if (selection.toString().length > 0) {
                setSelectedText(selection.toString());
            } else {
                setSelectedText('');
            }
        } else if (document.selection && document.selection.type !== "Control") {
            const text = document.selection.createRange().text;
            if (text) {
                setSelectedText(text);
            } else {
                setSelectedText('');
            }
        }
    };

    const handleKeyDown = (event) => {
        if ((event.altKey) ) {
            addToWordList(selectedText.trim().toLowerCase());
        }
    };

    // Function to find and replace words with bold tags
 


    return (
        <>
            <div className='new-line p-5' onMouseUp={handleSelectionChange} onKeyDown={handleKeyDown} tabIndex="0" style={{ padding: '10px', border: '5px solid var(--bg-solid)'}}>
                {!inputText && <div></div>}
                <div>
                    {inputText && <> <div dangerouslySetInnerHTML={{ __html: highlightWords(inputText, wordList) }} /> </>}
                </div>
            </div>
        </>

    )
}

export default TextAddToWordList