import React from 'react'
import { useAuth } from '../../store/AuthStore'

function ShowBalanceComponent({ className }) {
    const { user } = useAuth()
    return (
        user?.balance && <div className={className}>Баланс: {user?.balance}⚡</div>
    )
}

export default ShowBalanceComponent