import { highlightWords } from "./highlightWords";

export const makeCopyToClipboardText = (wordsDictionary, key) => {
    if (!wordsDictionary.hasOwnProperty(key)) return ''; // Проверяем наличие ключа и возвращаем пустую строку если ключ не существует
    //🔹
    let wordString = `🔹 <b>${key}</b>\n`;

    const appendArray = (array) => {
        array && array.forEach((value) => {
            if (typeof value === 'string') { // Проверяем, что значение является строкой
                wordString += highlightWords(value, [`${key}`]) + '<br>';
                // wordString += value + '<br>';
            } else {
                console.warn(`Invalid value found in the array for key ${key}:`, value);
            }
        });
    };

    // Добавляем данные в строку
    appendArray(wordsDictionary[key].phonetics);
    wordString += '<br>';
    appendArray(wordsDictionary[key].definitions);
    wordString += '<br>';
    appendArray(wordsDictionary[key].usages);
    wordString += '<br>';
    appendArray(wordsDictionary[key].translations.map((translation) => {return '<font color="#8d8da6">'+translation+'</font>'}));
    wordString += '<br>';

    return wordString;
};