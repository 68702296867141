import React from 'react'
import { useEnglishLessonStore } from '../store/englishLessonStore'

function TextAddToWordListWithButtonComponent({ inputText }) {
    const { addToWordList, wordList } = useEnglishLessonStore()

    const lines = inputText.split('\n');

    return (
        <>
            {lines.map((line) => {
                const wordClean = line.trim().replace(/^-+|-+$|\*+$/g, '').replace(/[0-9()]/g, '').trim().toLowerCase()
                return (
                    !wordList.includes(wordClean) &&
                    <div key={'chooseWords' + wordClean} className='words-list-item-component rounded shadow-box'>
                        <span className='pointer' onClick={() => { addToWordList(wordClean) }}>{wordClean}</span>
                    </div>
                )
            })}
        </>

    )
}

export default TextAddToWordListWithButtonComponent