import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import TextAreaComponent from '../TextAreaComponent'
import AutoMeaningComponent from '../ai/AutoMeaningComponent'


function MainWorkAreaBlockComponent() {
    return (
        <>
            <Row>
                <Col>
                    <h1>Работа с текстом</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Accordion className='shadow-box'>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Текст <span className=' text-secondary' style={{ fontSize: '10px' }} >(select + alt / option)</span></Accordion.Header>
                            <Accordion.Body>
                                <TextAreaComponent />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header style={{ verticalAlign: 'middle' }}>Значения слов</Accordion.Header>
                            <Accordion.Body>
                                <AutoMeaningComponent />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </>
    )
}

export default MainWorkAreaBlockComponent